(function() {
    window.addEventListener("message",function(evt){

        try {
            var originWhitelist = [
                "https://forms.ingbank.pl",
                "https://srcmakler.ingbank.pl",
                "https://forms-u.ingbank.pl",
                "https://forms.ing.pl",
                "https://forms-u.ing.pl",
                "https://www.inglease.pl",
                "https://ferryt-u.pl.ing-ad"
            ];

            if(originWhitelist.indexOf(evt.origin) < 0 || evt.source.parent !== window) return;

            var message = JSON.parse(evt.data),
                messageFunction = message["function"];

            if (messageFunction === "eval") return;

            if(messageFunction && typeof window[messageFunction] === 'function'){
                window[messageFunction].apply(this, message.parameters);
            }
        } catch(e) {}
    }, false);

    /*
     * Methods that can be managed through
     */
    window.showDivs = function(t) {
        if (void 0 !== t) {
            "string" == typeof t && (t = [t]);
            for (var n = 0; n < t.length; n++)
                $(t[n]).show()
        }
    }
    ,
    window.hideDivs = function(t) {
        if (void 0 !== t) {
            "string" == typeof t && (t = [t]);
            for (var n = 0; n < t.length; n++)
                $(t[n]).hide()
        }
    }
    ,
    window.pushGAEvent = function() {
        var t = arguments[0];
        if (null != t && "object" == typeof t)
            try {
                t.event = t.event || "eftunel",
                t.eft_parent = window.location.protocol + "//" + window.location.hostname + window.location.pathname,
                dataLayer.push(t)
            } catch (t) {
                console.log("Failed to serve event to dataLayer", t)
            }
    }
    ,
    window.initResizer = function() {
        var t = arguments[0].split(":")[0].substr(13);
        document.getElementById(t).contentWindow.postMessage(arguments[0], "https://forms-u.ingbank.pl"),
        document.getElementById(t).contentWindow.postMessage(arguments[0], "https://forms.ingbank.pl"),
        document.getElementById(t).contentWindow.postMessage(arguments[0], "https://forms.ing.pl"),
        document.getElementById(t).contentWindow.postMessage(arguments[0], "https://forms-u.ing.pl"),
        document.getElementById(t).contentWindow.postMessage(arguments[0], "https://www.inglease.pl")
    }
    ,
    window.resizeIframe = function() {
        if (void 0 !== arguments[0]) {
            var t = document.getElementById(arguments[0].id);
            t.height = arguments[0].height || 300,
            t.width = arguments[0].width || "100%"
        }
    }
})();
